<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title>{{ YNghiaCacConSoData.id !== '' ? `Cập nhật thông tin số ${YNghiaCacConSoData.chi_so}` : 'Thêm ý nghĩa các con số' }}</b-card-title>
      </b-card-header>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <!-- Nhóm -->
              <validation-provider
                #default="validationContext"
                name="Nhóm"
                rules="required"
              >
                <b-form-group
                  label="Nhóm"
                  label-for="nhom-y-nghia"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="YNghiaCacConSoData.nhom"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="nhom"
                    :clearable="false"
                    input-id="nhom-y-nghia"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <!-- Chỉ số -->
              <validation-provider
                #default="validationContext"
                name="Chỉ số"
                rules="required"
              >
                <b-form-group
                  label="Chỉ số"
                  label-for="chi-so"
                >
                  <b-form-input
                    id="chi-so"
                    v-model="YNghiaCacConSoData.chi_so"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <!-- Chủ đề -->
              <validation-provider
                #default="validationContext"
                name="Chủ đề"
                rules="required"
              >
                <b-form-group
                  label="Chủ đề"
                  label-for="chu-de"
                >
                  <b-form-input
                    id="chu-de"
                    v-model="YNghiaCacConSoData.chu_de"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <!-- Ngắt trang đầu tiên -->
              <validation-provider
                #default="validationContext"
                name="Ngắt trang đầu tiên"
                rules="required"
              >
                <b-form-group
                  label="Ngắt trang đầu tiên"
                  label-for="ngat-trang-dau-tien"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="YNghiaCacConSoData.ngat_trang"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="ngat_trang"
                    :clearable="false"
                    input-id="ngat-trang-dau-tien"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <!-- Cách trên -->
              <validation-provider
                #default="validationContext"
                name="Cách mép trên trang"
                rules="required"
              >
                <b-form-group
                  label="Cách mép trên trang"
                  label-for="cach-mep-tren-trang"
                >
                  <b-form-input
                    id="cach-mep-tren-trang"
                    v-model="YNghiaCacConSoData.cach_tren"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <!-- Tên file ảnh -->
              <validation-provider
                #default="validationContext"
                name="Tên file ảnh"
                rules="required"
              >
                <b-form-group
                  label="Tên file ảnh"
                  label-for="ten-file-anh"
                >
                  <b-form-input
                    id="ten-file-anh"
                    v-model="YNghiaCacConSoData.ten_file_anh"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <b-form-group
            label="Nội dung / Luận giải ngắn"
          >
            <quill-editor
              v-model="YNghiaCacConSoData.noi_dung"
              :options="editorOption"
            />
          </b-form-group>

          <b-form-group
            label="Những việc có thể xảy ra"
          >
            <quill-editor
              v-model="YNghiaCacConSoData.nhung_viec_co_the_xay_ra"
              :options="editorOption"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Lưu lại
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{name: 'y-nghia-cac-con-so'}"
            >
              Hủy
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import {
  BRow, BCol, BForm, BFormGroup, BFormInvalidFeedback, BFormInput,
  BCard, BCardTitle, BCardHeader, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import yNghiaCacConSoStoreModule from '../yNghiaCacConSoStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
    BCardTitle,
    BCardHeader,
    BFormInput,
    BCard,
    BRow,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BCol,
    BButton,
  },
  data() {
    return {
      required,
      nhom: [
        { label: 'Đường đời', value: 'Đường đời' },
        { label: 'Chỉ số đường đời KID', value: 'Đường đời trẻ em' },
        { label: 'Đường đời cha mẹ', value: 'Đường đời cha mẹ' },
        { label: 'Chỉ số ngày sinh', value: 'Chỉ số ngày sinh' },
        { label: 'Chỉ số ngày sinh KID', value: 'Ngày sinh trẻ em' },
        { label: 'Chỉ số tâm hồn', value: 'Chỉ số tâm hồn' },
        { label: 'Chỉ số tâm hồn KID', value: 'Tâm hồn trẻ em' },
        { label: 'Số trưởng thành', value: 'Số trưởng thành' },
        { label: 'Số trưởng thành KID', value: 'Trưởng thành trẻ em' },
        { label: 'Số trưởng thành Cha mẹ', value: 'Số trưởng thành cha mẹ' },
        { label: 'Số thử thách', value: 'Số thách thức' },
        { label: 'Số thử thách KID', value: 'Thử thách trẻ em' },
        { label: 'Số thử thách Cha mẹ', value: 'Số thử thách cha mẹ' },
        { label: 'Tháng thần số', value: 'Tháng thần số' },
        { label: 'Năm thần số', value: 'Năm thần số' },
        { label: 'Chỉ số vận mệnh', value: 'Chỉ số vận mệnh' },
        { label: 'Chỉ số vận mệnh KID', value: 'Vận mệnh trẻ em' },
        { label: 'Chỉ số vòng đời', value: 'Chỉ số vòng đời' },
        { label: 'Số lý trí', value: 'Số lý trí' },
        { label: 'Đỉnh trưởng thành', value: 'Đỉnh trưởng thành' },
        { label: 'Số bản thể', value: 'Số bản thể' },
        { label: 'Chỉ số hướng nghiệp', value: 'Chỉ số hướng nghiệp' },
        { label: 'Năm thần số trước - sau', value: 'Năm thần số trước - sau' },
        { label: 'Số thử thách thói quen', value: 'Số thử thách thói quen' },
      ],
      ngat_trang: [
        { key: 0, label: 'Vị trí trang đầu tiên' },
        { key: 1, label: 'Ngắt sang trang số 2' },
      ],
      editorOption: {
        theme: 'snow',
      },
    }
  },
  setup() {
    const blankYNghiaCacConSoData = {
      id: '',
      chi_so: '',
      noi_dung: '',
      nhom: '',
      chu_de: '',
      tom_tat: '',
      ten_file_anh: '',
      ngat_trang: 0,
      cach_tren: 0,
      nhung_viec_co_the_xay_ra: '',
    }

    const YNghiaCacConSoData = ref(JSON.parse(JSON.stringify(blankYNghiaCacConSoData)))

    const USER_APP_STORE_MODULE_NAME = 'app-y-nghia-cac-con-so'

    const resetuserData = () => {
      YNghiaCacConSoData.value = JSON.parse(JSON.stringify(blankYNghiaCacConSoData))
    }
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, yNghiaCacConSoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const currentUser = getUserData()
    const toast = useToast()
    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }

    const onSubmit = () => {
      store.state.showBlock = true

      Object.assign(YNghiaCacConSoData.value, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
      store.dispatch('app-y-nghia-cac-con-so/save', YNghiaCacConSoData.value)
        .then(response => {
          store.state.showBlock = false
          if (YNghiaCacConSoData.value.id !== '') {
            router.replace('/y-nghia-cac-con-so')
              .then(() => {
                showToast('success', 'Thông báo', 'BellIcon', response.data.content)
              })
              .catch(() => {
              })
          } else {
            showToast('success', 'Thông báo', 'BellIcon', response.data.content)
            resetuserData()
          }
        })
        .catch(e => {
          if (typeof e.response !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          } else if (typeof e.message !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.message)
          } else {
            showToast('danger', 'Thông báo', 'BellIcon', e)
          }
          store.state.showBlock = false
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    if (router.currentRoute.params.id !== undefined) {
      store.state.showBlock = true

      store.dispatch('app-y-nghia-cac-con-so/loadModel', {
        id: router.currentRoute.params.id,
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
        .then(response => {
          YNghiaCacConSoData.value = response.data
          store.state.showBlock = false
        })
        .catch(error => {
          if (error.response.status === 404) {
            resetuserData()
          }
          store.state.showBlock = false
        })
    }

    return {
      onSubmit,
      YNghiaCacConSoData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  mounted() {
  },
}
</script>

<style>

</style>
